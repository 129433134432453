//rafce
import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
// import {Navbar} from './components/Utilities';
import ContactPage from './components/contactPage/ContactPage';
import MaintenancePage from './components/errorPage/MaintenancePage';

import Navbar  from './components/utilities/Navbar';


import './App.css';
import './index.css';


const App = () => {
  return (
    <Router>
      <div className='App'>
          {/* <div className='gradient_bg'>
                  <Navbar />
              </div> */}
            <Routes>
              <Route path="/" element={<MaintenancePage bgcolor = "white"/>} />
              <Route path="/contact" element={<ContactPage bgcolor = "#001E35"/>} />
              {/* <Route path="/" element={<ContactPage />} /> */}
            </Routes>
      </div>
    </Router>
   
  )
}

export default App

