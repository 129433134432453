import React from 'react';
import './contactForm.css';
// instalamos emailjs-com, en caso de no usarla mas hay que desintalarlo
import emailjs from 'emailjs-com';
import { Grid, TextField } from '@material-ui/core';
import { useState, useRef } from 'react';

const ContactForm = () => {

  const inputStyles = {
    color: 'white', // Cambia el color del texto a blanco
    fontFamily: 'Urbanist, sans-serif', // Cambia la fuente del texto
  };

  const [formData, setFormData] = useState({
    name: '',
    namecompany: '',
  });

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const [errors, setErrors] = useState({});

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  };

  const validatePhone = (phone) => {
    const re = /^\+\d{10}$/;
    return re.test(phone);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevdata) => ({
      ...prevdata,[name]:value,}));
    
  };
 
  const handleSubmit = async(event) => {
    event.preventDefault();

    // Realizar validaciones aquí
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = 'El nombre es requerido';
    }

    if (!formData.namecompany) {
      newErrors.namecompany = 'El apellido es requerido';
    }

    if (!email || !validateEmail(email)) {
      newErrors.email = 'Ingresa un correo electrónico válido';
    }

    // if (!phone || !validatePhone(phone)) {
    //   newErrors.phone = 'Ingresa un número de teléfono válido';

    // }

    // Si hay errores, actualiza el estado de los errores y no envíes el formulario
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      console.log("ERRORS");
      console.log(newErrors);
    } else {
      try {
        // const response = await fetch('http://localhost:5001/contact_form', {
          const response = await fetch('https://test-du78.onrender.com/contact_form', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: formData.name,
            namecompany: formData.namecompany,
            email: email,
            phone: phone,
            message: message,
            onlyemail: false,
          }),
        });
  
        if (response.status === 201) {
          console.log('Contacto creado exitosamente');
          // Aquí puedes realizar cualquier acción adicional después de que se haya guardado el contacto
        } else {
          console.error('Error en el servidor');
        }
      } catch (error) {
        console.error('Error en la solicitud:', error);
      }
     
      // console.log('Formulario enviado:', formData);
    }
  };

  return (
    <div className="container_form_contact">
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} container>
          <h2 className="text_header_form">Vamos a hablar </h2>
          <p className="text_header_subtitle_form">
            Te responderemos cuanto antes
          </p>
        </Grid>

        <Grid item xs={12} md={8}>
          <form  onSubmit={handleSubmit}>
            {/* Fila 1: Nombre y Apellido */}
            <Grid container spacing={2} className="fields_style_form_contact">
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Nombre"
                  fullWidth
                  InputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Nombre Negocio"
                  fullWidth
                  InputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  name="namecompany"
                  value={formData.namecompany}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>

            {/* Fila 2: Email y Teléfono */}
            <Grid container spacing={2} className="fields_style_form_contact">
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email"
                  hintText="example@email.com"
                  type="email"
                  fullWidth
                  value={email}
                  InputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>   
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Teléfono"
                  type='phone'
                  fullWidth
                  InputProps={{ style: inputStyles }}
                  InputLabelProps={{ style: inputStyles }}
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className='fields_style_form_contact'>
            <Grid item xs={12} sm={12}>
              {/* Fila 3: Mensaje */}
              <TextField
                label="Mensaje"
                fullWidth
                multiline
                rows={4}
                InputProps={{ style: inputStyles }}
                InputLabelProps={{ style: inputStyles }}
                onChange={(e) => setMessage(e.target.value)}
                value={message}
              />
              </Grid>
            </Grid>
            {/* Botón de Enviar */}
            <Grid container justifyContent="center">
              <button type="submit" className="button_send_contact_style">Enviar</button>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default ContactForm;