import React from 'react';
import './contactEmail.css';
import imagen_contacto_email from '../../assets/imagen_contacto_email.svg'


import {Grid, Typography, Paper} from '@material-ui/core';

const ContactEmail = () => {
  
  return (
   
      <Grid container itemspacing={2}  justifyContent="center" className="contact_by_email_information_style">
        {/* Columna Izquierda */}
        <Grid item xs={12} md={6} container justify="center"  alignItems="center">
        <div className="content" style={{ textAlign: "center" }}>
          <h3 className='email_styles'> ¿Ya eres cliente nuestro?</h3>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <a href="mailto:support@adspromotions.us" className='email_contact_styles' >support@adspromotions.us</a>
        </div>
          </div>
        </Grid>
        
        {/* Columna Derecha */}
        <Grid item xs={12} md={6} container alignItems="center" justifyContent="center">
        <img
              className='image_contacto_email'
              src={imagen_contacto_email}
              alt="Imagen"
             
            />
        </Grid>
      </Grid>
    
  );
};

export default ContactEmail;