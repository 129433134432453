import React from 'react';
import './contactPage.css';
import Footer from '../utilities/Footer';
import ContactEmail from './ContactEmail';
import ContactForm from './ContactForm';
import ContactInfo from './ContactInfo';
import ContactSave from './ContactSave';
import Image from '../utilities/Image';
import ContactBackground from './ContactBackground';


const ContactPage = (props) => {
  const setPageStyles = () => {
    document.documentElement.style.setProperty('--color-bg', props.bgcolor);
  };

  setPageStyles();
 
  return (
    <div className="layout_contact_page">
      <ContactBackground />
      <ContactForm />
      <ContactInfo />
      <ContactSave />
      <ContactEmail />
      {/* 
            <Footer /> */}
    </div>
  );
}

export default ContactPage;