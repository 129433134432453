import React from 'react';
import Modal from 'react-modal';
import { MdEmail } from 'react-icons/md';
import test_modal_email_tochange from '../../assets/test_modal_email_tochange.jpg';

import './successModal.css';

const SuccessModal = ({ isOpen, onRequestClose }) => {
  return (
    <Modal
  isOpen={isOpen}
  onRequestClose={onRequestClose}
  contentLabel="Success Message"
  className='modal_content'
  
  style={{    
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 999 
    }
  }}
>
  <div   className='modal_header'  >
    {/* Puedes agregar tu imagen como fondo aquí */}
    <img src={test_modal_email_tochange} alt="Imagen de correo enviado con éxito" className='image_style_sent_email_model' />
  </div>
  <div   className='modal_body'   >
    <h3 className='tittle_style_sent_email_model'>¡Correo enviado con éxito!</h3>
    <p className='parragraph_style_sent_email_model'>Te contactaremos lo antes posible.</p>
    <button className='button_style_sent_email_model'  onClick={onRequestClose} >
      Cerrar
    </button>
  </div>
</Modal>
  );
};

export default SuccessModal;