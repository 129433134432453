import React from 'react';
import './maintenancePage.css';
import { Grid , TextField} from '@material-ui/core';
import logo_ads_promotions_mantenimiento from '../../assets/logo_ads_promotions_mantenimiento.svg';
import imagen_mantenimiento from '../../assets/imagen_mantenimiento.svg';
import ads_promotions_mantenimiento from '../../assets/ads_promotions_mantenimiento.svg';
import SuccessModal from '../utilities/SuccessModal';
import { Link } from 'react-router-dom';
import { useState } from 'react';


const MaintenancePage = (props) => {
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const setPageStyles = () => {
        document.documentElement.style.setProperty('--color-bg', props.bgcolor);
      };
      const handleSubmit = async(event) => {
        event.preventDefault();
    
        // Realizar validaciones aquí
        const newErrors = {};
    
        // Si hay errores, actualiza el estado de los errores y no envíes el formulario
        if (Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
          console.log("ERRORS");
          console.log(newErrors);
        } else {
          try {
            const response = await fetch('https://test-du78.onrender.com/save_email', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                name: '',
                namecompany: '',
                email: email,
                phone: '',
                message: '',
                onlyemail: true,
              }),
            });
      
            if (response.status === 201) {
              setEmail('');
              setIsSuccessModalOpen(true);

              console.log('Contacto creado exitosamente');
              // Aquí puedes realizar cualquier acción adicional después de que se haya guardado el contacto
            } else {
              console.error('Error en el servidor');
            }
          } catch (error) {
            console.error('Error en la solicitud:', error);
          }
        }
        
      };
      
    setPageStyles();

    //Brevo
        // const BrevoKey = "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";
        // const ListId = '2';

        // const handleSubmit = async (e) => {
        //     e.preventDefault();
            
        //     const email = e.target.email.value;

        //     try {
        //         const response = await fetch(`https://api.brevo.com/v1/lists/${ListId}/contacts`, {
        //       method: 'POST',
        //       headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': `Bearer ${BrevoKey}`  
        //       },
        //       body: JSON.stringify({ email }) 
        //     });
        //       } catch (error) {
        //         console.log(error);
        //       }
            
            
        // }

  return (

    <section className='body_background_mantenimiento'>
      <section>
        <Grid container itemspacing={2} justifyContent="space-between">
          <Grid item>
            <img src={logo_ads_promotions_mantenimiento} alt="Imagen" />
          </Grid>
          <Grid item>
            <Link to="/contact">
            <button
              className="button_contacto_maintenance_style" variant="contained" >Contacto </button>
            </Link>
            
          </Grid>
        </Grid>
      </section>
      
      <section>
        <Grid container itemspacing={2} >
          {/* Columna Izquierda */}
          <Grid item xs={6} md={6} container className='left_container_style_mantenimiento' >
            <h1 className="title_header_style_maintanence">
              {" "}
              Transformamos tus sueños en código, tu futuro en beneficios y el
              potencial en realidad{" "}
            </h1>

            <div className="container_form_mantenimiento">
              <h2 className="subtitle_header_style_maintanence">
                Recibe una nortificación cuando estemos listos
              </h2>
              {/* <form onSubmit={handleSubmit}>Brevo */ }
              <form onSubmit={handleSubmit} >
              <div className="form_mantenimiento">
                <input type='email' placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} name="email" className='input_form_style_mantenimiento'></input>
                <button
                  type="submit"
                 
                  className="button_form_maintenance_style"
            
                >            
                  Enviar
                </button>
                </div>
              </form>
              {/* Modal de éxito */}
              <SuccessModal
                isOpen={isSuccessModalOpen}
                onRequestClose={() => setIsSuccessModalOpen(false)}
              />
            </div>
          </Grid>

          <Grid item xs={6} md={6} container alignItems="center" justifyContent="center" >
            <img
              className="image_mantenimiento"
              src={imagen_mantenimiento}
              alt="Imagen"
            />
          </Grid>

          {/* Columna Derecha */}
        </Grid>
      </section>
      <section>
        <p className='animacion_texto_mantenimiento'> Ads Promotions</p>
      </section>
    </section>
  );
}

export default MaintenancePage;