import React from 'react';
import './contactBackgound.css';
import imagen_binario from '../../assets/imagen_binario.svg';


const ContactBackground = () => {
  return (
    <div >
      <div class="circle circle_1"></div>
      <div class="circle circle_2"></div>
      <div class="circle circle_3"></div>
      <div class="circle circle_4"></div>
      <div class="circle circle_5"></div>
      <div class="circle circle_6"></div>
      <div class="circle circle_7"></div>
      <img src={imagen_binario} loading="lazy" alt="" class="foto_binarios" />
      <img src={imagen_binario} loading="lazy" alt="" class="foto_binarios foto_binarios_2" />

      {/* <    
        div class="w-layout-blockcontainer container_form w-container"> */}
    </div>
  );
}

export default ContactBackground;