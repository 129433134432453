import React from 'react';
import './contactInfo.css';
import { Grid, Button, Typography, Link} from '@material-ui/core';
// import icono_correo_contacto from '../../assets/icono_correo_contacto.svg';
// import icono_telefono_contacto from '../../assets/icono_telefono_contacto.svg';
// import icon_email_contact from '../../assets/icon_email_contact.svg';
// import icon_callus_contact from '../../assets/icon_callus_contact.svg';
// import icon_sms_contact from '../../assets/icon_sms_contact.svg';
// import icon_whatsapp_contact from '../../assets/icon_whatsapp_contact.svg';
import icon_email_contact_final from '../../assets/icon_email_contact_final.svg';
import icon_callus_contact_final from '../../assets/icon_callus_contact_final.svg';
import icon_sms_contact_final from '../../assets/icon_sms_contact_final.svg';
import icon_whatsapp_contact_final from '../../assets/icon_whatsapp_contact_final.svg';



const ContactInfo = () => {
  return (
    <div>
      {/* <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        style={{ marginTop: "10em", marginBottom: "1em" }}
      >
        <Grid item xs={12} md={6} container justifyContent="center" >
          <h3 align="center" gutterBottom>
            ¿Necesitas que te ayudemos a conseguir más{" "}
            <span style={{ color: "#3cb7ec" }}>
              clientes realmente interesados
            </span>{" "}
            en tu producto o servicio?
          </h3>
        </Grid>

        <Grid container xs={12} md={8} spacing={2} justifyContent="center" className='contact_features_style'  style={{ marginTop: "6em"}}>
          <Grid item xs={6} sm={3} align="center" justifyContent='center'>
            <img src={icon_email_contact_final} alt="Email" height="50" />
            <h4 className='text_style_feature_contact' >Email</h4>
            <p className='text_style_feature_contact paragraph_text_style_contact'>
              Si necesitas hacer un trabajo y quieres enviarnos junto con el
              chat documentos de ejemplo, esta es la mejor opción.
            </p>
            <Grid item  className='text_style_feature_contact'>
            <a href="mailto:info@adspromotions.us" color="inherit" className='text_style_feature_contact'>
              info@adspromotions.us
            </a>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={3} align="center" justifyContent='center'>
            <img src={icon_callus_contact_final} alt="Phone" height="60" />
            <h4 className='text_style_feature_contact' >Llámanos</h4>
            <p className='text_style_feature_contact paragraph_text_style_contact'>
              La forma más rápido de ponerte en contacto con nosotros
              directamente, aunque si estamos en un meeting puede que no podamos
              contestar.
            </p>
            <Grid item  className='text_style_feature_contact'>
            <a href="tel:+17863953378" color="inherit" className='text_style_feature_contact link_style_feature_contact'>
              +1 (786) 395 33-78
            </a>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={3} align="center" justifyContent='center'>
            <img src={icon_sms_contact_final} alt="SMS" height="60" />
            <h4 className='text_style_feature_contact' >SMS</h4>
            <p className='text_style_feature_contact paragraph_text_style_contact'>
              Déjanos un mensaje y te devolveremos la llamada en cuanto antes.
            </p>
            <Grid item  className='text_style_feature_contact'>
            <a href="sms:+17863953378" target="_blank" color="inherit" className='text_style_feature_contact link_style_feature_contact'>
              +1 (786) 395 33-78
            </a>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={3} align="center" justifyContent='center'>
            <img src={icon_whatsapp_contact_final} alt="WhatsApp" height="60" />
            <h4 className='text_style_feature_contact' >WhatsApp</h4>
            <p className='text_style_feature_contact paragraph_text_style_contact'>
              Si tienes Whatsapp, puede llamarnos o enviarnos un mensaje. (Es la
              opción preferida por muchos de nuestros clientes)
            </p>
            <Grid item  className='text_style_feature_contact'>
            <a
              href="https://wa.me/+17863953378"
              target="_blank"
              color="inherit"
              className='text_style_feature_contact link_style_feature_contact'
            >
              https://wa.me/+17863953378
            </a>
            </Grid>
          </Grid>
        </Grid>


       
      </Grid> */}

      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        className="contact_information_container_style"
      >
        <Grid item xs={12} md={6} container justifyContent="center">
          <h3 className="header_info_style_contact" align="center">
            ¿Necesitas que te ayudemos a conseguir más{" "}
            <span style={{ color: "#3cb7ec" }}>
              clientes realmente interesados
            </span>{" "}
            en tu producto o servicio?
          </h3>
        </Grid>

        <Grid
          container
          xs={12}
          md={8}
          spacing={2}
          justifyContent="center"
          className="contact_features_style"
        >
          <Grid
            item
            xs={6}
            sm={6}
            md={3}
            align="center"
            justifyContent="center"
            className="card_container_style_contact"
          >
            <a href="mailto:info@adspromotions.us">
              <img
                src={icon_email_contact_final}
                alt="Email"
                className="icon_feature_contact"
                href="mailto:info@adspromotions.us"
              />
            </a>
            <h4
              className="text_style_feature_contact title_info_features_contact"
              href="mailto:info@adspromotions.us"
            >
              Email
            </h4>
            <p className="text_style_feature_contact paragraph_text_style_contact">
              Si necesitas hacer un trabajo y quieres enviarnos junto con el
              chat documentos de ejemplo, esta es la mejor opción.
            </p>
            <Grid item className="text_style_feature_contact">
              <a
                href="mailto:info@adspromotions.us"
                color="inherit"
                className="text_style_feature_contact link_style_feature_contact"
              >
                info@adspromotions.us
              </a>
            </Grid>
          </Grid>

          <Grid
            item
            xs={6}
            sm={6}
            md={3}
            align="center"
            justifyContent="center"
            className="card_container_style_contact"
          >
            <a href="sms:+17863953378">
              <img
                src={icon_sms_contact_final}
                alt="SMS"
                className="icon_feature_contact"
              />
            </a>
            <h4
              className="text_style_feature_contact title_info_features_contact"
              href="sms:+17863953378"
            >
              SMS
            </h4>
            <p className="text_style_feature_contact paragraph_text_style_contact">
              Déjanos un mensaje y te devolveremos la llamada en cuanto antes.
            </p>
            <Grid item className="text_style_feature_contact">
              <a
                href="sms:+17863953378"
                target="_blank"
                color="inherit"
                className="text_style_feature_contact link_style_feature_contact"
              >
                +1 (786) 395 33-78
              </a>
            </Grid>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={3}
            align="center"
            justifyContent="center"
            className="card_container_style_contact"
          >
            <a href="tel:+17863953378">
              <img
                src={icon_callus_contact_final}
                alt="Phone"
                className="icon_feature_contact"
              />
            </a>
            <h4
              className="text_style_feature_contact title_info_features_contact"
              href="tel:+17863953378"
            >
              Llámanos
            </h4>
            <p className="text_style_feature_contact paragraph_text_style_contact">
              La forma más rápido de ponerte en contacto con nosotros
              directamente, aunque si estamos en un meeting puede que no podamos
              contestar.
            </p>
            <Grid item className="text_style_feature_contact">
              <a
                href="tel:+17863953378"
                color="inherit"
                className="text_style_feature_contact link_style_feature_contact"
              >
                +1 (786) 395 33-78
              </a>
            </Grid>
          </Grid>

          <Grid
            item
            xs={6}
            sm={6}
            md={3}
            align="center"
            justifyContent="center"
            className="card_container_style_contact"
          >
            <a a href="https://wa.me/+17863953378">
              <img
                src={icon_whatsapp_contact_final}
                alt="WhatsApp"
                className="icon_feature_contact"
              />
            </a>
            <h4
              className="text_style_feature_contact title_info_features_contact"
              href="https://wa.me/+17863953378"
            >
              WhatsApp
            </h4>
            <p className="text_style_feature_contact paragraph_text_style_contact">
              Si tienes Whatsapp, puede llamarnos o enviarnos un mensaje. (Es la
              opción preferida por muchos de nuestros clientes)
            </p>
            <Grid item className="text_style_feature_contact">
              <a
                href="https://wa.me/+17863953378"
                target="_blank"
                color="inherit"
                className="text_style_feature_contact link_style_feature_contact"
              >
                https://wa.me/+17863953378
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ContactInfo;