import React from 'react';
import './contactSave.css';

import { Grid, Button, Typography } from '@material-ui/core';



const ContactSave = () => {
  const vCardUrl = process.env.PUBLIC_URL + 'download_files/Tarjeta_contacto_Carlos_AdsPromotions.vcf';
  const vCardFileName = 'Tarjeta_contacto_Carlos_AdsPromotions.vcf';
  return (
    <Grid container spacing={2} alignItems="center" justifyContent='center' className="download_container_style_contact">
       <Grid item xs={12} md={8} container justifyContent='center' >
       <h3 className='text_save_contact' >Guarda nuestro contacto para que estés <span style={{ color: '#009CFF' }} >protegido siempre de Scam. </span> </h3>
      </Grid>
      
      <Grid item xs={12} md={8} container justifyContent='center'>
        <button className='button_save_contact_style' variant="contained" href={vCardUrl} download={vCardFileName}>Guardar Contacto</button>
      </Grid>
    </Grid>
  );
}

export default ContactSave;